import { Component, OnInit, OnDestroy } from '@angular/core';
import { MaintenanceQuestionaryDialogComponent } from '@ildes/basic/maintenance-questionary-dialog/maintenance-questionary-dialog.component';
import { LuminarySearcherDialogComponent } from '@ildes/basic/luminary-searcher-dialog/luminary-searcher-dialog.component';
import { GalleryDialogComponent } from '@ildes/basic/gallery-dialog/gallery-dialog.component';
import { UseMaterialDialogComponent } from '@ildes/basic/use-material-dialog/use-material-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStore } from '@ildes/stores/session.store';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { SyncDataService } from '@ildes/services/sync-data.service';
import * as actions from '@ildes/views/technician-dashboard-maintenances/assigned-maintenances.actions';
import * as actionsQuestionaries from '@ildes/views/technician-dashboard-maintenances/daily-questionaries.actions';
import getAvailableUsedMaterials from '@ildes/utils/calculate-available-materials';
import { ImageCacheService } from '@ildes/services/image-cache.service';
import questionsMaintenance from './questions-maintenance';
import questionsHighPermission from './questions-high-permission';
import { map, first, tap, catchError, mergeMap } from 'rxjs/operators';
import { of, throwError, zip } from 'rxjs';
import { UnsyncExpansionsService } from '@ildes/services/unsync-expansions.service';
import { UnsyncModernizationsService } from '@ildes/services/unsync-modernizations.service';

import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';


const ERROR_CODE_ASSIGNED_MATERIAL = 4;
const LUMINARY_DOESNT_EXIST = 5;
@Component({
  selector: 'app-repair-maintenance',
  templateUrl: './repair-maintenance.component.html',
  styleUrls: ['./repair-maintenance.component.css']
})
export class RepairMaintenanceComponent implements OnInit, OnDestroy {
  public downloading;
  maintenance: any;
  user;
  assignedMaintenances;
  loadingSync: boolean;
  pendingRequest: boolean = false;
  subscriptions = [];
  dailyQuestionary
  maintenanceTask;
  route;
  apiManager;
  pendingIdenfifyLuminary;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private _route: ActivatedRoute,
    private _apiManager: ApiManagerService,
    private syncData: SyncDataService,
    private cacheService: ImageCacheService,
    private router: Router,
    private unsyncExpansions: UnsyncExpansionsService,
    private unsyncModernizations: UnsyncModernizationsService,
  ) {
    this.route = _route;
    this.apiManager = _apiManager;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      SessionStore.getInstance().get$().subscribe((data) => {
        this.user = data?.user;
        this.store.dispatch(
          actions.loadMaintenances({
            assigned: this.user.id,
            state: undefined,
            options: { cache: true }
          })
        )
      })
    );

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        const id = params.id;

        this.store.select('assignedMaintenances').pipe(first()).subscribe((data) => {
          this.maintenance = data.find((maintenance) => maintenance.id === id);
          if (!this.maintenance) {
            return;
          }
          this.fetchMaintenanceTask().subscribe();

          this.subscriptions.push(this.findQuestionary());
          this.store.dispatch(
            actionsQuestionaries.loadDailyQuestionaries()
          )
        });

        this.subscriptions.push(
          this.store.select('assignedMaintenances').subscribe((data) => {
            this.assignedMaintenances = data;
            this.maintenance = data.find((maintenance) => maintenance.id === id);
          })
        );
      })
    );
  }

  get pendingSignByMe() {
    if (!this.maintenanceTask) {
      return false;
    }

    return this.maintenanceTask.users.indexOf(this.user.id) >=0 && this.maintenanceTask.usersWhoPerformTheTask.indexOf(this.user.id) < 0;
  }

  fetchMaintenanceTask() {
    return this.apiManager.getTasks({
      type: 'DOCUMENT_SIGN',
      subtype: 'WORK_AT_MAINTENANCE_PERMISSION',
      'metadata.maintenance': this.maintenance.id
    }).pipe(
      map(({ data }: any) => {
        this.maintenanceTask = data[0];
        return { data };
      })
    );
  }

  getConsignedDate() {
    return this.maintenance.repairmentDate ? new Date(this.maintenance.repairmentDate) : new Date()
  }

  findQuestionary() {
    this.store.select('dailyQuestionaries').pipe(first()).subscribe((data) => {
      const consignedDate = this.getConsignedDate();

      this.dailyQuestionary = data.find(({ projectId, date }) => projectId === this.maintenance.project.id && this.sameDate(consignedDate, date));

      if (!this.dailyQuestionary) {
        this.fetchDailyQuestionary();
      }
    });

    return this.store.select('dailyQuestionaries').subscribe((data: any) => {
      const consignedDate = this.getConsignedDate();

      this.dailyQuestionary = data.find(({ projectId, date }) => projectId === this.maintenance.project.id && this.sameDate(consignedDate, date));
    })
  }

  fetchDailyQuestionary(technicianId?) {
    const consignedDate = this.getConsignedDate();

    this.apiManager.getDailyQuestionary(this.maintenance.project.id, { date: consignedDate.toISOString(), technicianId } , { error: false }).subscribe((data: any) =>{
      if (!data.data) {
        return;
      }
      this.dailyQuestionary = {
        answers: data.data.questionary,
        date: new Date(data.data.date),
        projectId: this.maintenance.project.id
      };
    });
  }
  
  openQuestionary(readOnly = false) {
    // completar cuestionario
    const dialog = this.dialog.open(MaintenanceQuestionaryDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: {
        readOnly,
        questions: questionsMaintenance,
        answers: this.maintenance?.questionary
      }
    });

    dialog.afterClosed().subscribe((data) => {
      if (!data?.commit || readOnly) {
        return false;
      }
      this.pendingRequest = true;
      const openDate = new Date().toISOString();
      // poner en curso
      this.apiManager
        .updateMaintenance(this.maintenance.id, { state: 'IN_PROGRESS', answers: data.answers, questions: questionsMaintenance, openDate })
        .subscribe( () => {
          this.pendingRequest = false;
          this.performSaveQuestionary({ answers: data.answers, openDate });
        }, (error) => {
          this.pendingRequest = false;
          if (!error.code || error.code > 0) {
            return;
          }
          this.performSaveQuestionary({ answers: data.answers, openDate }, { pendingSync: true });
        })
    });
  }

  openTaskIdentificationDialog() {
    // abrimos predictivo de luminarias
    const dialog = this.dialog.open(LuminarySearcherDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { maintenance: this.maintenance}
    });

    dialog.afterClosed().subscribe((data) => {
      if (!data?.luminary) {
        return;
      }
      this.pendingRequest = true;
      this.apiManager.updateMaintenance(this.maintenance.id, { luminary: data.luminary, height: data.height, ascendMethod: data.ascendMethod })
        .subscribe(() => {
          this.pendingRequest = false;
          this.performSaveLuminaryIdentification(data);
        }, (error) => {
          this.pendingRequest = false;
          if (!error.code || error.code > 0 && error.code !== LUMINARY_DOESNT_EXIST) {
            return;
          }
          if (error.code === LUMINARY_DOESNT_EXIST) {
            this.pendingIdenfifyLuminary = true;
          }
          this.performSaveLuminaryIdentification(data, { pendingSync: true });
        });
    });
  }
  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  openTakePhotos(step, readOnly = false) {
    // asociamos fotos a estado inicial o estado final
    const dialog = this.dialog.open(GalleryDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: {
        readOnly,
        photos: [...(this.maintenance.photos && this.maintenance.photos[step] || [])]
      }
    });

    dialog.afterClosed().subscribe(async (data) => {
      if (!data?.commit || readOnly) {
        return;
      }
      this.pendingRequest = true;
      const photos = await Promise.all(data.photos.map(async(photo) => {
        if (!photo.includes('/base64/')) {
          return photo;
        }
        const buffer = await this.cacheService.get(photo).then((response)=>response.blob());
        const base64 = await this.blobToBase64(buffer);

        return base64;
      }));
      this.apiManager.updateMaintenance(this.maintenance.id, {
        photos: {
          [step]: photos
        }
      }).subscribe(() => {
        this.pendingRequest = false;
        this.performSetPhotos(step, data);
      }, (error) => {
        this.pendingRequest = false;
        if (!error.code || error.code > 0) {
          return;
        }
        this.performSetPhotos(step, data, { pendingSync: true });
      });
    })

  }

  repair() {
    if (this.maintenance?.state === 'COMPLETED') {
      return;
    }
    // abrimos modal para elegir materiales
    const dialog = this.dialog.open(UseMaterialDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: {
        description: this.maintenance.description,
        maintenance: this.maintenance,
        availableMaterials: getAvailableUsedMaterials(this.user.confirmedMaterials, this.assignedMaintenances, this.unsyncExpansions.getItems(), this.unsyncModernizations.getItems()),
        usedMaterials: this.maintenance.usedMaterials?.map(({amount, reference}) => ({amount,reference})),
        extractedMaterials: this.maintenance.extractedMaterials?.map(({amount, reference}) => ({amount,reference})),
        pendingSyncUsedMaterials: this.maintenance.pendingSync?.repairmentData,
      }
    });

    dialog.afterClosed().subscribe((data) => {
      if (!data?.commit) {
        return;
      }

      const extractedMaterials = data.extractedMaterials.map(({amount, reference}) => ({amount,reference}));
      const usedMaterials = data.usedMaterials.map(({amount, reference}) => ({amount,reference}));

      // reparamos mantenimiento
      this.pendingRequest = true;
      this.apiManager.updateMaintenance(this.maintenance.id, {
        usedMaterials,
        extractedMaterials,
        description: data.description
      }).subscribe(() => {
        this.apiManager.getUserDetail(this.user.id).subscribe((user) => {
          this.pendingRequest = false;
          SessionStore.updateUserInfo(user, true);
        }, () => this.pendingRequest = false);
        this.performRepairMaintenance({
          usedMaterials,
          extractedMaterials,
          description: data.description
        });
      }, (error) => {
        this.pendingRequest = false;
        if (!error.code || error.code > 0) {
          return;
        }
        this.performRepairMaintenance({
          usedMaterials,
          extractedMaterials,
          description: data.description
        }, { pendingSync: true });
      })
    })
  }

  closeMaintenance() {
    this.pendingRequest = true;
    const repairmentDate = new Date().toISOString();

    this.syncData.syncMaintenance({...this.maintenance, state: 'CLOSED', repairmentDate}, { state: true }).subscribe((data) => {
      this.pendingRequest = false;
      this.maintenanceTask = data.data;
      this.performCloseMaintenance({state: 'CLOSED', repairmentDate});
    }, (error) => {
      this.pendingRequest = false;
      if (!error.code || error.code > 0 && error.code !== LUMINARY_DOESNT_EXIST) {
        return;
      }
      if (error.code === LUMINARY_DOESNT_EXIST) {
        this.pendingIdenfifyLuminary = true;
      }
      this.performCloseMaintenance({state: 'CLOSED', repairmentDate}, { pendingSync: true });
    });
  }

  get completedSteps() {
    return this.questionaryCompleted &&
      this.taskIdentificationCompleted &&
      this.initialPhotosCompleted &&
      this.repairmentCompleted &&
      this.finalPhotosCompleted;
  }

  get repairmentCompleted() {
    return this.maintenance?.usedMaterials
      && this.maintenance?.extractedMaterials;
  }

  get questionaryCompleted() {

    return this.maintenance?.questionary?.length && this.maintenance?.questionary?.reduce((res, answer) => res && answer.value, true);
  }

  get taskIdentificationCompleted() {
    return this.maintenance?.luminary && this.maintenance?.height > 0 && this.maintenance.ascendMethod;
  }

  get completedAnswers() {
    if (!this.maintenance?.questionary || !this.maintenance?.questionary[0]) {
      return;
    }
    return this.maintenance?.questionary?.filter((answer) => answer.value).length;
  }

  get initialPhotosCompleted() {
    return this.maintenance?.photos?.initial;
  }

  get finalPhotosCompleted() {
    return this.maintenance?.photos?.final;
  }

  get countAnswers() {
    return this.maintenance?.questionary?.length;
  }

  get descriptionStep0() {
    return this.completedAnswersDailyQuestionary ? 'Completado correctamente.' : 'Debes completar las preguntas para poder continuar con la labor.';
  }
  
  /* TODO: ver si la he firmado yo */
  get descriptionStep1() {
    if (!this.maintenance?.questionary || !this.maintenance?.questionary[0]) {
      return 'Visualiza el terreno y confirma que se cumplen todos los requisitos de seguridad antes de comenzar.';
    } else {
      return `Contestadas ${this.completedAnswers} de ${this.countAnswers} preguntas.`;
    }
  }

  get descriptionStep2() {

    if(this.maintenance?.luminary?.number) {
      return `Identificación de la luminaria: ${this.maintenance.luminary.number}<br/>
        Altura de trabajo: ${this.maintenance?.height || '-'}<br/>
        Método de ascenso: ${this.maintenance?.ascendMethod?.description || '-' }.`;
    }

    return 'Se necesitan datos acerca de la labor para completar este paso.';
  }

  get descriptionStep3() {
    if (!this.initialPhotosCompleted) {
      return 'Toma unos fotos para tener una trazabilidad del estado en el que te encontraste la luminaria.';
    }
    if (this.maintenance?.photos?.initial?.length) {
      return `Has añadido ${this.maintenance?.photos?.initial?.length} fotos.`;
    }

    return 'No has añadido fotos';
  }

  get descriptionStep4() {
    if (this.maintenance?.usedMaterials?.length) {
      return `Usado: ${this.humaniceMaterials(this.maintenance?.usedMaterials)}.<br>
              Retirado: ${this.humaniceMaterials(this.maintenance?.extractedMaterials)}.<br>
              Observaciones: ${this.maintenance?.description || '-'}.`
    }
    return 'Indica qué material has usado y qué material has retirado para la reparación.';
  }

  get descriptionStep5() {
    if (!this.finalPhotosCompleted) {
      return 'Toma unos fotos para tener una trazabilidad del estado en el que ha quedado la luminaria.';
    }
    if (this.maintenance?.photos?.final?.length) {
      return `Has añadido ${this.maintenance?.photos?.final?.length} fotos.`;
    }

    return 'No has añadido fotos';
  }

  humaniceMaterials(materials) {
    if (!materials?.length) {
      return '-'
    }
    return materials.map((material) => `${material.reference.name} x${material.amount}`).join(',');
  }

  get closed() {
    return this.maintenance?.state === 'CLOSED';
  }


  get pendingSync() {
    return this.dailyQuestionary?.pendingSync || Object.values(this.maintenance?.pendingSync || {}).find((value) => value === true);
  }

  private performSaveQuestionary({ answers, openDate }, options: any = { pendingSync: false }) {
    this.store.dispatch(
      actions.saveQuestionary({
        maintenanceId: this.maintenance.id,
        answers: answers,
        openDate,
        ...options.pendingSync && { pendingSync: options.pendingSync },
      })
    );
  }

  private performSaveLuminaryIdentification(data, options: any = { pendingSync: false }) {
    this.store.dispatch(
      actions.identifyLuminary({
        maintenanceId: this.maintenance.id,
        luminaryNumber: data.luminary,
        ascendMethod: data.ascendMethod,
        height: data.height,
        ...options.pendingSync && { pendingSync: options.pendingSync }
      })
    );
  }

  private performSetPhotos(step, data, options: any = { pendingSync: false }) {
    const payload = {
      maintenanceId: this.maintenance.id,
      photos: data.photos,
      commit: data.commit,
      ...options.pendingSync && { pendingSync: options.pendingSync }
    };
    this.store.dispatch(
      step === 'initial' ?
        actions.setPhotosInitialState(payload) :
        actions.setPhotosFinalState(payload)
    );
  }

  private performRepairMaintenance(data, options = { pendingSync: false }) {
    const usedMaterials = data.usedMaterials;

    const payload = {
      maintenanceId: this.maintenance.id,
      usedMaterials: data.usedMaterials,
      extractedMaterials: data.extractedMaterials,
      description: data.description,
      ...options.pendingSync && { pendingSync: options.pendingSync, localUsedMaterials: usedMaterials }
    };

    this.store.dispatch(
      actions.setRepairmentData(payload)
    );
  }

  private performCloseMaintenance(data = {state: 'CLOSED', repairmentDate: ''}, options = { pendingSync: false }) {
    this.store.dispatch(
      actions.changeState({
        maintenanceId: this.maintenance.id,
        repairmentDate: data.repairmentDate,
        state: data.state,
        ...options.pendingSync && { pendingSync: options.pendingSync}
      })
    );
  }

  private syncDailyQuestionary() {
    if (this.dailyQuestionary.pendingSync) {
      return this.apiManager.updateDailyQuestionary(this.maintenance.project.id, {
        questions: questionsHighPermission,
        answers: this.dailyQuestionary.answers,
        date: this.dailyQuestionary.date,
      }).pipe(
        tap( () => {
          this.store.dispatch(
            actionsQuestionaries.update({
              changes: { pendingSync: false },
              date: this.dailyQuestionary.date,
              projectId: this.maintenance.project.id,
            })
          );
        }),
        catchError((error) => {
          if (error.code === 1) {
            this.store.dispatch(
              actionsQuestionaries.update({
                changes: { answers: this.dailyQuestionary.answers.map(({id}) => ({ id, value: true })), pendingSync: false },
                date: this.dailyQuestionary.date,
                projectId: this.maintenance.project.id,
              })
            );
          }
          return throwError(error);
        })
      )
    }
    else {
      return of(null);
    }
  }
  public syncMaintenance(maintenance) {
    this.pendingRequest = true;

    const request = zip(
      this.syncDailyQuestionary(),
      this.syncData.syncMaintenance(maintenance),
    );

    request.subscribe((data) => {
      this.pendingRequest = false;
    }, (error) => {
      this.pendingRequest = false;
      
      if (error.code === ERROR_CODE_ASSIGNED_MATERIAL) {
        this.repair();
      }
      if (error.code === LUMINARY_DOESNT_EXIST) {
        this.pendingIdenfifyLuminary = true;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  _signOrGoToQuestionary() {
    this.fetchMaintenanceTask().subscribe(() => {
      if (this.maintenanceTask) {
        this.router.navigate(['/app/task'], { queryParams: { id: this.maintenanceTask.id } });
  
        return;
      } else {
        this.openQuestionary();
      }
    }, (error) => {
      // si tengo error de conexión:
      // - si ya completé el cuestionario el día de hoy y además tengo pendingSignDailyPermissionTask de hoy, no dejo continuar, indicando que no se puede firmar porque no hay conexión
      // - si no, dejamos continuar para que cambie el cuestionario
      if (!error.code || error.code > 0) {
        return;
      }
      this.openQuestionary();
    })
  }

  openDailyPermissionQuestionary(readOnly = false) {
    // completar cuestionario
    const dialog = this.dialog.open(MaintenanceQuestionaryDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: {
        readOnly,
        questions: questionsHighPermission,
        title: 'Cuestionario para trabajar en alturas',
        answers: this.answersDailyPermission,
      }
    });

    dialog.afterClosed().subscribe((data) => {
      if (!data?.commit || readOnly) {
        return;
      }
      
      this.apiManager.updateDailyQuestionary(this.maintenance.project.id, {
        questions: questionsHighPermission,
        answers: data.answers,
        date: new Date(),
      }).subscribe(() => {
        this.performSaveDailyPermission({ data }, false);
      }, (error) => {
        // en este caso ya se completó el cuestionario de forma correcta y no se puede modificar
        // restauramos a todo true
        if (error.code === 1) {
          data.answers.forEach((answer) => {
            answer.value = true;
          });

          this.performSaveDailyPermission({ data }, false);

          return;
        }
        if (!error.code || error.code > 0) {  
          return;
        }
        this.performSaveDailyPermission({ data }, true);
      });
    
    });
  }

  public performSaveDailyPermission({ data }, pendingSync = false) {
    if (!this.dailyQuestionary) {
      this.store.dispatch(
        actionsQuestionaries.newQuestionary({
          answers: data.answers,
          date: new Date(),
          projectId: this.maintenance.project.id,
          pendingSync
        })
      );
    } else if (this.dailyQuestionary) {
      this.store.dispatch(
        actionsQuestionaries.update({
          changes: { answers: data.answers, pendingSync },
          date: new Date(),
          projectId: this.maintenance.project.id,
        })
      );
    }
  }

  private sameDate(day1,day2) {
    return (
      day1.getFullYear() === day2.getFullYear() &&
      day1.getMonth() === day2.getMonth() &&
      day1.getDate() === day2.getDate()
    );
  }

  get descriptionStepSign() {
    // TODO: el pendingSync es con respecto a los pasos anteriores
    const pendingSync = this.pendingSync;

    if (pendingSync && !this.maintenanceTask) {
      return 'Sincroniza para poder generar y firmar la documentación';
    } else if (this.maintenanceTask) {
      return 'Pendiente firmar la documentación'
    }
    else {
      return 'Completa los pasos anteriores para poder generar la documentación';
    }
  }

  signTask() {
    this.router.navigate(['/app/task'], { queryParams: { id: this.maintenanceTask.id } });

    return;
  }

  postponeMaintenance() {
    this.pendingRequest = true;
    this.syncData.syncMaintenance(this.maintenance).subscribe(() => {
      const repairmentDate = new Date().toISOString();
      this.apiManager.postponeMaintenance(this.maintenance.id, {repairmentDate}).subscribe((data: any) => {
        this.store.dispatch(
          actions.changeState({
            maintenanceId: this.maintenance.id,
            state: 'CLOSED',
            repairmentDate
          })
        );
        this.pendingRequest = false;
        this.maintenanceTask = data.data;
      }, () => {
        this.pendingRequest = false;
      })
    }, (error) => {
      this.pendingRequest = false;
    });
  }

  get completedAnswersDailyQuestionary() {
    if (!this.dailyQuestionary || !this.answersDailyPermission[0]) {
      return;
    }
    return this.answersDailyPermission.every((answer) => answer.value);
  }

  get answersDailyPermission() {
    return this.dailyQuestionary?.answers || [];
  }

  get observationsInMaintenanceQuestionary() {
    return this.maintenance?.questionary?.filter((answer) => !answer.value)?.length > 0;
  }

  get pendingSignMaintenanceTask() {
    return this.maintenanceTask && this.maintenanceTask.state !== 'COMPLETED';
  }

  goToLuminary() {
    this.router.navigate(['/app/list-luminaries/create'], {
      state: {
        prefilled: true,
        number: this.maintenance.luminary.number,
        projectId: this.maintenance.project.id,
        projectName: this.maintenance.project.name,
      }
    });
  }

  private convertBlobToBase64 = (blob :Blob)=>new Promise ((resolve,reject) =>{
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () =>{
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  open(fileName) {
    this.downloading = true;
    this.apiManager.getFile(fileName, {}, { contentType: 'application/pdf', error: true }).subscribe(async (data: any) => {
      this.downloading = false;
      if (Capacitor.getPlatform() !== 'web' ) {
        const database64 = await this.convertBlobToBase64(data) as string;

        const savedFile = await Filesystem.writeFile({
          path: fileName.split('/').pop(),
          data: database64,
          directory: Directory.Cache,
        });

        const path = savedFile.uri;

        FileOpener.open({ filePath: path, contentType: data.type });

      } else {
        const url = window.URL.createObjectURL(data);

        Browser.open({ url });
      }
    }, () => {
      this.downloading = false;
    })
  }

  openDocument() {
    this.downloadDocumentation(this.maintenance.id).subscribe((data) => {
      this.open(data);
    });
  }

  downloadDocumentation(maintenanceId) {
    return this.apiManager.getTasks({
      type: 'DOCUMENT_SIGN',
      subtype: 'WORK_AT_MAINTENANCE_PERMISSION',
      'metadata.maintenance': maintenanceId
    }).pipe(
      mergeMap((taskData: any) => {
        const task = taskData.data[0];
        if (!task.usersWhoPerformTheTask?.length) {
          return [task.metadata.documentName];
        } else {
          return this.apiManager.getEvidence({ taskId: task.id })
            .pipe(
              map(({ data }: any) => data.signedDocumentName)
            )
        }
      })
    );
  }

}
