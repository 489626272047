import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AdvancedSearchLuminaryDialogComponent } from '../../views/advanced-search-luminary-dialog/advanced-search-luminary-dialog.component';
import constants from '@ildes/config/constants';

const ESTIMATED_RESOLUTION_DAYS = 2;

@Component({
  selector: 'app-create-maintenance',
  templateUrl: './create-maintenance.component.html',
  styleUrls: ['./create-maintenance.component.css']
})
export class CreateMaintenanceComponent implements OnInit {

  @ViewChild('stepper') vistualStepper: any;

  luminaries;
  loading;
  loadingLuminaries;
  pagination;

  filter: any;
  searchLuminaryAdapter;
  type;
  painType = constants.PAIN_TYPES[0];
  painTypes = constants.PAIN_TYPES;
  types = constants.TYPES;
  searchNeighborhoodAdapter;
  searchProjectAdapter;
  newMaintenanceGroupStep1 = new UntypedFormGroup({
    origin: new UntypedFormControl(),
    painTypeDescription: new UntypedFormControl(''),
  });
  newMaintenanceGroupStep2 = new UntypedFormGroup({
    luminary: new UntypedFormControl(''),
  });
  newMaintenanceGroupStep3 = new UntypedFormGroup({
    neighborhoodForm: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    selectedProjectForm: new UntypedFormControl('')
  });

  newMaintenanceGroupStep3PointDescription = new UntypedFormGroup({
    referencePointDescription: new UntypedFormControl('')
  });

  currenDate = new Date();
  estimatedResolutionDate = new Date(new Date().getTime() + ESTIMATED_RESOLUTION_DAYS * 24 * 3600 * 1000);

  constructor(
    private apiManager: ApiManagerService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.searchLuminaryAdapter = (data) => {
      return this.apiManager.listLuminaries({ number: data.text }).pipe(
        map(({ data }: any) => ( {data: this.normalize(data)}))
      )
    };
    this.searchNeighborhoodAdapter = (data) => {
      return this.apiManager.getFromCatalog('neighborhood', data)
    };
    this.searchProjectAdapter = (data) => {
      return this.apiManager.listProjects(data);
    };
  }

  normalize(data) {
    return data.map((object) => ({...object, ...{ name: object.number }}));
  }

  get step1IsValid() {
    return Boolean(this.type);
  }

  get step3IsValid() {
    if (this.selectedLuminary) {
      return true;
    }

    return Boolean((this.address?.typed || this.referencePointDescription  || this.neighborhood?.typed) && this.selectedProject?.selected);
  }

  get painTypeDescription() {
    return this.newMaintenanceGroupStep1.get('painTypeDescription')?.value
  }

  get address() {
    return this.newMaintenanceGroupStep3.get('address')?.value
  }

  get neighborhood() {
    return this.newMaintenanceGroupStep3.get('neighborhoodForm')?.value
  }

  get referencePointDescription() {
    return this.newMaintenanceGroupStep3PointDescription.get('referencePointDescription')?.value
  }

  get selectedProject() {
    return this.newMaintenanceGroupStep3.get('selectedProjectForm')?.value
  }

  advancedSearch() {
    const dialog = this.dialog.open(AdvancedSearchLuminaryDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { filter: this.filter }
    });

    dialog.afterClosed().subscribe((data = { closed: false }) => {

      if (data.closed) {
        return;
      }
      this.filter = data;
      this.list();

    });
  }
  list(pageIndex = 0) {
    const page = pageIndex ? { page: pageIndex + 1 } : {};
    const filter: any = this.getFilter();

    this.loadingLuminaries = true;

    this.apiManager.listLuminaries({...page, ...filter}).subscribe((luminaries: any) => {
      this.loadingLuminaries = false;
      this.luminaries = luminaries.data;
      this.pagination = luminaries.pagination;
    }, (data) => {
      this.loadingLuminaries = false;
    });
  }

  private getFilter() {
    const filter: any = {};

    if (this.filter?.project?.selected.id) {
      filter.projectId = this.filter.project.selected.id;
    }

    if (this.filter?.neighborhood) {
      filter.neighborhood = this.filter.neighborhood.typed;
    }

    if (typeof this.filter?.withoutNumber === 'boolean' && this.filter?.withoutNumber) {
      filter.number = null;
    } else if (this.filter?.number) {
      filter.number = this.filter.number;
    }

    if (this.filter?.location) {
      filter.latitude = this.filter.location.lat;
      filter.longitude = this.filter.location.lng;
      filter.maxDistance = 50;
    }

    if (this.filter?.number) {
      filter.number = this.filter.number;
    }

    return filter;
  }

  selectLuminary(luminary) {
    this.newMaintenanceGroupStep2.controls.luminary.setValue({
      typed: luminary.number,
      selected: luminary
    });
    this.filter = null;
    this.luminaries = null;
    this.pagination = null;
  }

  get selectedLuminary() {
    return this.newMaintenanceGroupStep2.controls?.luminary?.value?.selected;
  }

  setEstimatedResolutionDate($event) {
    this.estimatedResolutionDate = $event.selectedDate;
  }

  get latitude() {
    return this.address?.lat;
  }

  get longitude() {
    return this.address?.lng;
  }

  create() {
    const data = {
      type: this.type,
      ...this.type.id === 'CORRECTIVE' && { painType: this.painType },
      painDescription: this.painTypeDescription,
      luminary: this.selectedLuminary?.id,
      neighborhood: this.neighborhood?.typed,
      address: this.address?.typed,
      ... this.latitude  &&
        {
          geolocation: {
            type: 'Point',
            coordinates: [this.longitude, this.latitude]
          }
        },
      project: this.selectedProject?.selected?.id,
      estimatedResolutionDate: this.estimatedResolutionDate,
      locationReferenceDescription: this.referencePointDescription
    };
    this.loading = true;
    this.apiManager.createMaintenance(data).subscribe(() => {
      this.type = null;
      this.painType = constants.PAIN_TYPES[0];
      this.newMaintenanceGroupStep3.reset();
      this.newMaintenanceGroupStep2.reset();
      this.vistualStepper.reset();
      this.loading = false;
    }, () => {
      this.loading = false;
    })
  }
}
