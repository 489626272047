import { Component, OnInit } from '@angular/core';
import { RepairMaintenanceComponent } from '../repair-maintenance/repair-maintenance.component';
import { SessionStore } from '@ildes/stores/session.store';

@Component({
  selector: 'app-detail-maintenance',
  templateUrl: './detail-maintenance.component.html',
  styleUrls: ['./detail-maintenance.component.css']
})
export class DetailMaintenanceComponent extends RepairMaintenanceComponent {
  ngOnInit(): void {
    this.subscriptions.push(
      SessionStore.getInstance().get$().subscribe((data) => {
        this.user = data?.user;
      })
    );
    this.route.queryParams.subscribe(params => {
      this.apiManager.getDetailMaintenance(params.id).subscribe((data) => {
        this.maintenance = data;
        
        this.fetchMaintenanceTask().subscribe();

        this.fetchDailyQuestionary(this.maintenance.technician);
      })
    });
  }

  get descriptionStep3() {
    if (!this.initialPhotosCompleted) {
      return 'Aún no se han añadido fotos.';
    }
    if (this.maintenance?.photos?.initial?.length) {
      return `Se han añadido ${this.maintenance?.photos?.initial?.length} fotos.`;
    }

    return 'No se han añadido fotos';
  }

  get descriptionStep4() {
    if (this.maintenance?.usedMaterials?.length !== undefined) {
      return `Usado: ${this.humaniceMaterials(this.maintenance?.usedMaterials)}.<br>
              Retirado: ${this.humaniceMaterials(this.maintenance?.extractedMaterials)}.<br>
              Observaciones: ${this.maintenance?.description || '-'}.`
    }
    return 'Este paso aun no se ha completado.';
  }

  get descriptionStep5() {
    if (!this.finalPhotosCompleted) {
      return 'Aún no se han añadido fotos.';
    }
    if (this.maintenance?.photos?.final?.length) {
      return `Se han añadido ${this.maintenance?.photos?.final?.length} fotos.`;
    }

    return 'No se han añadido fotos';
  }
 
  openSigned() {
    this.apiManager.getEvidence({ taskId: this.maintenanceTask.id }).subscribe(({ data }) => {
      this.open(data.signedDocumentName);
    });
  }
}
