<connection-alert></connection-alert>
<mat-progress-bar mode="indeterminate" *ngIf="pendingRequest"></mat-progress-bar>
<div class="container">
  <div class="scrollable-content">
    <simple-header title="Detalle de mantenimiento" [back]="true" icon="close"></simple-header>
    <div class="item">
      <app-list-step-state-item
        [state]="completedAnswersDailyQuestionary ? 'COMPLETED': (answersDailyPermission?.length ? 'IN_PROGRESS': 'PENDING')"
        [disabled]="completedAnswersDailyQuestionary"
        (click)="openDailyPermissionQuestionary(true)"
        title="Cuestionario diario para trabajar en alturas"
        [description]="descriptionStep0">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="(questionaryCompleted || completedAnswers >= 0) ? 'COMPLETED': 'PENDING'"
        (click)="openQuestionary(true)"
        [disabled]="(questionaryCompleted || !(completedAnswers >= 0))"
        title="Confirmación de seguridad"
        [description]="descriptionStep1">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="taskIdentificationCompleted ? 'COMPLETED': 'PENDING'"
        [disabled]="true"
        title="Identificación de la labor"
        [description]="descriptionStep2">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="initialPhotosCompleted ? 'COMPLETED': 'PENDING'"
        [disabled]="!(maintenance?.photos?.initial?.length > 0)"
        (click)="openTakePhotos('initial', true)"
        title="Fotos del estado inicial"
        [description]="descriptionStep3">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="repairmentCompleted ? 'COMPLETED': 'PENDING'"
        [disabled]="true"
        title="Datos de la reparación"
        [description]="descriptionStep4">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="finalPhotosCompleted ? 'COMPLETED': 'PENDING'"
        (click)="openTakePhotos('final', true)"
        [disabled]="!(maintenance?.photos?.final?.length > 0)"
        title="Fotos del estado final"
        [description]="descriptionStep5">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
    </div>
    <h3 *ngIf="maintenanceTask" class="padding-top-8">Documentación generada</h3>
    <div *ngIf="maintenanceTask && maintenanceTask?.state !== 'COMPLETED'">
      <div class="padding-top-8 padding-bottom-8" *ngIf="pendingSignByMe">
        <app-alert-dialog
          (click)="signTask()"
          text="Pendiente de firmar el permiso de trabajo."
          linkText="Ir a tarea de firma"
          status="advise"></app-alert-dialog>
      </div>
      <app-alert-dialog
        *ngIf="!pendingSignByMe"
        text="El permiso de trabajo está pendiente de firmar por alguno de los intervinientes."
        status="advise"></app-alert-dialog>
      <div class="attachment-list" *ngIf="!pendingSignByMe">
        <div class="button-container">
          <div mat-fab extended (click)="openDocument()">
            <mat-icon>picture_as_pdf</mat-icon>
            <span class="text"> Ver documentación </span>
          </div>
        </div>
      </div>
    </div>
    <div class="padding-top-8 padding-bottom-8" *ngIf="maintenanceTask && maintenanceTask?.state === 'COMPLETED'">
      <div class="attachment-list">
        <div class="button-container">
          <div mat-fab extended (click)="openSigned()">
            <mat-icon>picture_as_pdf</mat-icon>
            <span class="text"> Ver documentación </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
