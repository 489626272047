<div class="container">
  <div class="scrollable-content">
    <simple-header title="Crear mantenimiento" [back]="true" icon="close"></simple-header>
    <mat-vertical-stepper [linear]="true" #stepper>
      <mat-step [completed]="step1IsValid">
        <ng-template matStepLabel>Datos del mantenimiento</ng-template>
        <form [formGroup]="newMaintenanceGroupStep1" autocomplete="off">
          <dropdown-selector class="full-width margin-top-8" label="Tipo de mantenimiento" [types]="types" [(selected)]="type"></dropdown-selector>
          <dropdown-selector [disabled]="type?.id !== 'CORRECTIVE'" class="full-width" label="Tipo de daño del alumbrado público" [noEmptyValue]="true" [types]="painTypes" [(selected)]="painType"></dropdown-selector>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Observaciones</mat-label>
            <textarea rows="2" matInput formControlName="painTypeDescription"></textarea>
          </mat-form-field>
          <div>
            <h3>Fecha estimada de resolución</h3>
            <date-selector [date]="estimatedResolutionDate" [minYear]="currenDate.getFullYear()" [maxYear]="currenDate.getFullYear()+1" (onChangeDate)="setEstimatedResolutionDate($event)" ></date-selector>
          </div>
          <button [disabled]="!step1IsValid" mat-button matStepperNext>Siguiente</button>
          <button [disabled]="!step1IsValid" mat-button (click)="stepper.selectedIndex=2" color="primary">Omitir selección de luminaria</button>
        </form>
      </mat-step>
      <mat-step [optional]="true">
        <ng-template matStepLabel> Identificación de la luminaria </ng-template>
        <form [formGroup]="newMaintenanceGroupStep2" autocomplete="off">
          <div class="inline-flex">
            <predictive-searcher
              class="column"
              formControlName="luminary"
              label="Número de luminaria"
              onValueChanges="changeLuminary($event)"
              [service]="searchLuminaryAdapter"></predictive-searcher>
            <button mat-icon-button aria-label="búsqueda avanzada" (click)="advancedSearch()">
              <mat-icon>filter_alt_outline</mat-icon>
            </button>
          </div>
          <div *ngFor="let luminary of luminaries" class="item">
            <list-luminary-item
              class="actionable"
              (click)="selectLuminary(luminary)"
              [number]="luminary.number"
              [project]="luminary.project?.name || 'Sin proyecto'"
              [address]="luminary.address"
              [ubication]="luminary.geolocation"
              [neighborhood]="luminary.neighborhood"
              [technology]="luminary.technology"
              [power]="luminary.power"
            ></list-luminary-item>
            <mat-divider></mat-divider>
          </div>
          <button mat-button matStepperNext>Siguiente</button>
        </form>
      </mat-step>
      <mat-step >
        <ng-template matStepLabel> Ubicación y proyecto </ng-template>
        <form [formGroup]="newMaintenanceGroupStep3" autocomplete="off" *ngIf="!selectedLuminary">
          <predictive-searcher
            [mandatorySelection]="true"
            formControlName="selectedProjectForm"
            label="Proyecto"
            [service]="searchProjectAdapter"></predictive-searcher>
          <input-place
            class="column"
            label="Dirección"
            placeholder="CLL 33 45"
            dark
            formControlName="address"
            appearance="fill"></input-place>
          <predictive-searcher
            label="Barrio"
            formControlName="neighborhoodForm"
            [service]="searchNeighborhoodAdapter"
            class="column margin-right-8"></predictive-searcher>
        </form>
        <form [formGroup]="newMaintenanceGroupStep3PointDescription" autocomplete="off">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Punto de referencia de ubicación</mat-label>
            <input matInput maxlength="80" formControlName="referencePointDescription">
          </mat-form-field>
        </form>
        <div *ngIf="selectedLuminary">
          <key-value-item key="Proyecto" [value]="selectedLuminary.project.name"></key-value-item>
          <key-value-item key="Dirección" [value]="selectedLuminary.address"></key-value-item>
        </div>
        <button mat-button matStepperNext [disabled]="!step3IsValid">Siguiente</button>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel> Confirmación </ng-template>
        <key-value-item key="Tipo de mantenimiento" [value]="type?.description"></key-value-item>
        <key-value-item key="Tipo de daño de alumbrado público" [value]="painType?.description"></key-value-item>
        <key-value-item key="Observaciones del daño" [value]="newMaintenanceGroupStep1.controls.painTypeDescription?.value" *ngIf="newMaintenanceGroupStep1.controls.painTypeDescription?.value"></key-value-item>
        <key-value-item key="Fecha estimada de resolución" [value]="estimatedResolutionDate | formatDate" *ngIf="estimatedResolutionDate"></key-value-item>
        <div *ngIf="selectedLuminary">
          <key-value-item key="Proyecto" [value]="selectedLuminary.project.name"></key-value-item>
          <key-value-item key="Dirección" [value]="selectedLuminary.address"></key-value-item>
        </div>
        <div *ngIf="!selectedLuminary">
          <key-value-item key="Proyecto" [value]="newMaintenanceGroupStep3.controls.selectedProjectForm?.value?.typed"></key-value-item>
          <key-value-item key="Dirección" [value]="newMaintenanceGroupStep3.controls.address?.value?.typed"></key-value-item>
          <key-value-item key="Barrio" *ngIf="newMaintenanceGroupStep3.controls" [value]="newMaintenanceGroupStep3.controls.neighborhoodForm?.value?.typed"></key-value-item>
          <key-value-item key="Punto de referencia de ubicación" *ngIf="newMaintenanceGroupStep3.controls && referencePointDescription" [value]="referencePointDescription"></key-value-item>
        </div>
        <div class="align-center padding-top-32">
          <div class="padding-bottom-8">
            <button (click)="create()" [disabled]="loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
          </div>
          <div>
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>
